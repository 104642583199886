export default {
    'page.menu-title.default': 'Aveyrin',
    'page.menu-title.detail.top': 'Top',
    'page.menu-title.detail.vademecum': 'Vade Mecum',
    'page.menu-title.detail.blog': 'Blog',
    'page.menu-title.auth.login': 'Login',
    'page.menu-title.page.notfound': 'Not found',
    'page.menu-title.page.access-denied': 'Access denied',
    'page.menu-title.page.access-error': 'Error',
    'page.menu-title.detail.dashboard': 'Dashboard',
    'page.menu-title.detail.landing': 'Aveyrin',
    'page.menu-title.detail.wind': 'Wind',
    'page.menu-title.detail.earth': 'Earth',
    'page.menu-title.detail.water': 'Water',
    'page.menu-title.detail.fire': 'Fire',
    'page.menu-title.detail.electric': 'Electric',
    'page.menu-title.detail.ice': 'Ice',
    'page.menu-title.detail.shadow': 'Shadow',
    'page.menu-title.detail.about': 'About',
    'page.menu-title.detail.faq': 'FAQ',
    'page.menu-title.detail.privacypolicy': 'Privacy Policy',
    'page.menu-title.detail.termsofservice': 'Terms of Service',
    'page.menu-title.detail.constitution': 'Constitution',
    'page.menu-title.detail.avatch': 'Avatch',
    'page.menu-title.detail.biveech': 'Biveech',
    'page.menu-title.detail.cealtean': 'Cealtean',
    'page.menu-title.detail.devauntt': 'Devauntt',
    'page.menu-title.detail.ecchale': 'Ecchale',
    'page.menu-title.detail.fyulle': 'Fyulle',
    'page.menu-title.detail.heubane': 'Heubane',
    'page.menu-title.detail.ikkleen': 'Ikkleen',
    'page.menu-title.detail.kychain': 'Kychain',
    'page.menu-title.detail.lyhvien': 'Lyhvien',
    'page.menu-title.detail.meaacub': 'Meaacub',
    'page.menu-title.detail.ogyann': 'Ogyann',
    'page.menu-title.detail.pezzean': 'Pezzean',
    'page.menu-title.detail.qouvena': 'Qouvena',
    'page.menu-title.detail.rahhven': 'Rahhven',
    'page.menu-title.detail.sciiabe': 'Sciiabe',
    'page.menu-title.detail.taraan': 'Taraan',
    'page.menu-title.detail.uaaten': 'Uaaten',
    'page.menu-title.detail.vaciian': 'Vaciian',
    'page.menu-title.detail.xertav': 'Xertav',
    'page.menu-title.detail.yrotcen': 'Yrotcen',
    'page.menu-title.detail.draavanhs': 'Draavanhs',
    'page.menu-title.detail.behva': 'Behva',
    'page.menu-title.detail.fiolon': 'Fiolon',
    'page.menu-title.detail.drissnet': 'Drissnet',
    'page.menu-title.detail.bloatrint': 'Bloatrint',
    'page.menu-title.detail.turkoitt': 'Turkoitt',
    'page.menu-title.detail.ekentri': 'Ekentri',
    'page.menu-title.detail.stiiqua': 'Stiiqua',
    'page.menu-title.detail.puisontry': 'Puisontry',
    'page.menu-title.detail.foshett': 'Foshett',
    'page.menu-title.detail.wventry': 'Wventry',
    'page.menu-title.detail.oxavett': 'Oxavett',
    'page.menu-title.detail.paivant': 'Paivant',
    'page.menu-title.detail.skvail': 'Skvail',
    'page.menu-title.detail.jevuae': 'Jevuae',
    'page.menu-title.detail.daveece': 'Daveece',
    'page.menu-title.detail.genacryst': 'Genacryst',
    'page.menu-title.detail.Aerial_Impaler': 'Aerial Impaler',
    'page.menu-title.detail.Lancing_Pusher': 'Lancing Pusher',
    'page.menu-title.detail.Bubby_Blaster': 'Bubby Blaster',
    'page.menu-title.detail.Plasmatic_Annihilator': 'Plasmatic Annihilator',
    'page.menu-title.detail.Plubbles_Discharger': 'Plubbles Discharger',
    'page.menu-title.detail.documentation': 'Documentation',
    'page.menu-title.detail.page-empty': 'Empty',
    'page.menu-title.detail.page-crud': 'CRUD',
    'page.menu-title.detail.uikit-formlayout': 'FormLayout',
    'page.menu-title.detail.uikit-input': 'Input',
    'page.menu-title.detail.uikit-button': 'Button',
    'page.menu-title.detail.uikit-table': 'Table',
    'page.menu-title.detail.uikit-list': 'List',
    'page.menu-title.detail.uikit-tree': 'Tree',
    'page.menu-title.detail.uikit-panel': 'Panel',
    'page.menu-title.detail.uikit-overlay': 'Overlay',
    'page.menu-title.detail.uikit-media': 'Media',
    'page.menu-title.detail.uikit-message': 'Message',
    'page.menu-title.detail.uikit-file': 'File',
    'page.menu-title.detail.uikit-menu': 'Menu',
    'page.menu-title.detail.uikit-charts': 'Charts',
    'page.menu-title.detail.uikit-misc': 'Misc',
    'page.menu-title.detail.uikit-timeline': 'Timeline'
};
