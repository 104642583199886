const PageSpec = {
    APP: {
        //================//
        //
        // Home Page
        //
        //================//
        LANDING: {
            path: '/',
            name: 'landing',
            meta: {
                title: 'page.menu-title.detail.landing',
                permissions: []
            },
            component: () => import('@/views/pages/Landing.vue')
        },

        //================//
        //
        // Top Recommendations
        //
        //================//
        TOP: {
            path: '/top',
            name: 'top',
            meta: {
                title: 'page.menu-title.detail.top',
                permissions: []
            },
            component: () => import('@/views/pages/company/Top.vue')
        },

        //================//
        //
        // Vade Mecum
        //
        //================//
        VADEMECUM: {
            path: '/vade-mecum',
            name: 'vade-mecum',
            meta: {
                title: 'page.menu-title.detail.vademecum',
                permissions: []
            },
            component: () => import('@/views/pages/company/VadeMecum.vue')
        },

        //================//
        //
        // DISCORD REDIRECT
        //
        //================//
        DISCORDDIRECT: {
            path: '/discord',
            name: 'discord',
            meta: {
                title: 'page.menu-title.detail.discord',
                permissions: []
            },
            component: () => import('@/views/pages/Discord.vue')
        },
        //================//
        //
        // Blog Page
        //
        //================//
        BLOG: {
            path: '/blog',
            name: 'blog',
            meta: {
                title: 'page.menu-title.detail.blog',
                permissions: []
            },
            component: () => import('@/views/pages/blog/Blog.vue')
        },
        BLOGPOSTS: {
            path: '/blog/:id',
            name: 'blogposts',
            meta: {
                title: 'page.menu-title.detail.blog',
                permissions: []
            },
            component: () => import('@/views/pages/blog/BlogPost.vue')
        },
        //================//
        //
        // Elements
        //
        //================//
        WIND: {
            path: '/wind',
            name: 'wind',
            meta: {
                title: 'page.menu-title.detail.wind',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Wind.vue')
        },
        EARTH: {
            path: '/earth',
            name: 'earth',
            meta: {
                title: 'page.menu-title.detail.earth',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Earth.vue')
        },
        WATER: {
            path: '/water',
            name: 'water',
            meta: {
                title: 'page.menu-title.detail.water',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Water.vue')
        },
        FIRE: {
            path: '/fire',
            name: 'fire',
            meta: {
                title: 'page.menu-title.detail.fire',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Fire.vue')
        },
        ELECTRIC: {
            path: '/electric',
            name: 'electric',
            meta: {
                title: 'page.menu-title.detail.electric',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Electric.vue')
        },
        ICE: {
            path: '/ice',
            name: 'ice',
            meta: {
                title: 'page.menu-title.detail.ice',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Ice.vue')
        },
        SHADOW: {
            path: '/shadow',
            name: 'shadow',
            meta: {
                title: 'page.menu-title.detail.shadow',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Shadow.vue')
        },

        //================//
        //
        // Company
        //
        //================//

        ABOUT: {
            path: '/about',
            name: 'about',
            meta: {
                title: 'page.menu-title.detail.about',
                permissions: []
            },
            component: () => import('@/views/pages/company/About.vue')
        },

        //================//
        //
        // Community
        //
        //================//

        FAQ: {
            path: '/faq',
            name: 'faq',
            meta: {
                title: 'page.menu-title.detail.faq',
                permissions: []
            },
            component: () => import('@/views/pages/community/FAQ.vue')
        },

        //================//
        //
        // Legal
        //
        //================//

        CONSTITUTION: {
            path: '/constitution',
            name: 'constitution',
            meta: {
                title: 'page.menu-title.detail.constitution',
                permissions: []
            },
            component: () => import('@/views/pages/legal/Constitution.vue')
        },

        PRIVACYPOLICY: {
            path: '/privacy-policy',
            name: 'privacypolicy',
            meta: {
                title: 'page.menu-title.detail.privacypolicy',
                permissions: []
            },
            component: () => import('@/views/pages/legal/PrivacyPolicy.vue')
        },

        TERMSOFSERVICE: {
            path: '/terms-of-service',
            name: 'termsofservice',
            meta: {
                title: 'page.menu-title.detail.termsofservice',
                permissions: []
            },
            component: () => import('@/views/pages/legal/TermsOfService.vue')
        },

        //================//
        //
        // Quintinity
        //
        //================//

        AVATCH: {
            path: '/avatch',
            name: 'avatch',
            meta: {
                title: 'page.menu-title.detail.avatch',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Avatch.vue')
        },
        NECTARINE: {
            path: '/nectarine',
            name: 'nectarine',
            meta: {
                title: 'page.menu-title.detail.avatch',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Avatch.vue')
        },
        BIVEECH: {
            path: '/biveech',
            name: 'biveech',
            meta: {
                title: 'page.menu-title.detail.biveech',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Biveech.vue')
        },
        KIWI: {
            path: '/kiwi',
            name: 'kiwi',
            meta: {
                title: 'page.menu-title.detail.biveech',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Biveech.vue')
        },
        CEALTEAN: {
            path: '/cealtean',
            name: 'cealtean',
            meta: {
                title: 'page.menu-title.detail.cealtean',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Cealtean.vue')
        },
        HONEYDEW: {
            path: '/honeydew',
            name: 'honeydew',
            meta: {
                title: 'page.menu-title.detail.cealtean',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Cealtean.vue')
        },
        DEVAUNTT: {
            path: '/devauntt',
            name: 'devauntt',
            meta: {
                title: 'page.menu-title.detail.devauntt',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Devauntt.vue')
        },
        DRAGONFRUIT: {
            path: '/dragonfruit',
            name: 'dragonfruit',
            meta: {
                title: 'page.menu-title.detail.devauntt',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Devauntt.vue')
        },
        ECCHALE: {
            path: '/ecchale',
            name: 'ecchale',
            meta: {
                title: 'page.menu-title.detail.ecchale',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ecchale.vue')
        },
        LIME: {
            path: '/lime',
            name: 'lime',
            meta: {
                title: 'page.menu-title.detail.ecchale',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ecchale.vue')
        },
        FYULLE: {
            path: '/fyulle',
            name: 'fyulle',
            meta: {
                title: 'page.menu-title.detail.fyulle',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Fyulle.vue')
        },
        PINEAPPLE: {
            path: '/pineapple',
            name: 'pineapple',
            meta: {
                title: 'page.menu-title.detail.fyulle',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Fyulle.vue')
        },
        HEUBANE: {
            path: '/heubane',
            name: 'heubane',
            meta: {
                title: 'page.menu-title.detail.heubane',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Heubane.vue')
        },
        AGAVE: {
            path: '/agave',
            name: 'agave',
            meta: {
                title: 'page.menu-title.detail.heubane',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Heubane.vue')
        },
        IKKLEEN: {
            path: '/ikkleen',
            name: 'ikkleen',
            meta: {
                title: 'page.menu-title.detail.ikkleen',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ikkleen.vue')
        },
        PEACH: {
            path: '/peach',
            name: 'peach',
            meta: {
                title: 'page.menu-title.detail.ikkleen',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ikkleen.vue')
        },
        KYCHAIN: {
            path: '/kychain',
            name: 'kychain',
            meta: {
                title: 'page.menu-title.detail.kychain',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Kychain.vue')
        },
        RASPBERRY: {
            path: '/raspberry',
            name: 'raspberry',
            meta: {
                title: 'page.menu-title.detail.kychain',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Kychain.vue')
        },
        LYHVIEN: {
            path: '/lyhvien',
            name: 'lyhvien',
            meta: {
                title: 'page.menu-title.detail.lyhvien',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Lyhvien.vue')
        },
        PEAR: {
            path: '/pear',
            name: 'pear',
            meta: {
                title: 'page.menu-title.detail.lyhvien',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Lyhvien.vue')
        },
        MEAACUB: {
            path: '/meaacub',
            name: 'meaacub',
            meta: {
                title: 'page.menu-title.detail.meaacub',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Meaacub.vue')
        },
        GRAPE: {
            path: '/grape',
            name: 'grape',
            meta: {
                title: 'page.menu-title.detail.meaacub',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Meaacub.vue')
        },
        OGYANN: {
            path: '/ogyann',
            name: 'ogyann',
            meta: {
                title: 'page.menu-title.detail.ogyann',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ogyann.vue')
        },
        WATERMELON: {
            path: '/watermelon',
            name: 'watermelon',
            meta: {
                title: 'page.menu-title.detail.ogyann',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ogyann.vue')
        },
        PEZZEAN: {
            path: '/pezzean',
            name: 'pezzean',
            meta: {
                title: 'page.menu-title.detail.pezzean',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Pezzean.vue')
        },
        LEMON: {
            path: '/lemon',
            name: 'lemon',
            meta: {
                title: 'page.menu-title.detail.pezzean',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Pezzean.vue')
        },
        QOUVENA: {
            path: '/qouvena',
            name: 'qouvena',
            meta: {
                title: 'page.menu-title.detail.qouvena',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Qouvena.vue')
        },
        PLUM: {
            path: '/plum',
            name: 'plum',
            meta: {
                title: 'page.menu-title.detail.qouvena',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Qouvena.vue')
        },
        RAHHVEN: {
            path: '/rahhven',
            name: 'rahhven',
            meta: {
                title: 'page.menu-title.detail.rahhven',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Rahhven.vue')
        },
        BANANA: {
            path: '/banana',
            name: 'banana',
            meta: {
                title: 'page.menu-title.detail.rahhven',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Rahhven.vue')
        },
        SCIIABE: {
            path: '/sciiabe',
            name: 'sciiabe',
            meta: {
                title: 'page.menu-title.detail.sciiabe',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Sciiabe.vue')
        },
        POMEGRANATE: {
            path: '/pomegranate',
            name: 'pomegranate',
            meta: {
                title: 'page.menu-title.detail.sciiabe',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Sciiabe.vue')
        },
        TARAAN: {
            path: '/taraan',
            name: 'taraan',
            meta: {
                title: 'page.menu-title.detail.taraan',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Taraan.vue')
        },
        BLUEBERRY: {
            path: '/blueberry',
            name: 'blueberry',
            meta: {
                title: 'page.menu-title.detail.taraan',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Taraan.vue')
        },
        UAATEN: {
            path: '/uaaten',
            name: 'uaaten',
            meta: {
                title: 'page.menu-title.detail.uaaten',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Uaaten.vue')
        },
        BLACKBERRY: {
            path: '/blackberry',
            name: 'blackberry',
            meta: {
                title: 'page.menu-title.detail.uaaten',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Uaaten.vue')
        },
        VACIIAN: {
            path: '/vaciian',
            name: 'vaciian',
            meta: {
                title: 'page.menu-title.detail.vaciian',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Vaciian.vue')
        },
        CRANBERRY: {
            path: '/cranberry',
            name: 'cranberry',
            meta: {
                title: 'page.menu-title.detail.vaciian',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Vaciian.vue')
        },
        XERTAV: {
            path: '/xertav',
            name: 'xertav',
            meta: {
                title: 'page.menu-title.detail.xertav',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Xertav.vue')
        },
        ACAI: {
            path: '/acai',
            name: 'acai',
            meta: {
                title: 'page.menu-title.detail.xertav',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Xertav.vue')
        },
        YROTCEN: {
            path: '/yrotcen',
            name: 'yrotcen',
            meta: {
                title: 'page.menu-title.detail.yrotcen',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Yrotcen.vue')
        },
        GRAPEFRUIT: {
            path: '/grapefruit',
            name: 'grapefruit',
            meta: {
                title: 'page.menu-title.detail.yrotcen',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Yrotcen.vue')
        },

        //================//
        //
        // CHARACTERS
        //
        //================//

        DRAAVANHS: {
            path: '/draavanhs',
            name: 'draavanhs',
            meta: {
                title: 'page.menu-title.detail.draavanhs',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Draavanhs.vue')
        },
        BEHVA: {
            path: '/behva',
            name: 'behva',
            meta: {
                title: 'page.menu-title.detail.behva',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Behva.vue')
        },
        FIOLON: {
            path: '/fiolon',
            name: 'fiolon',
            meta: {
                title: 'page.menu-title.detail.fiolon',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Fiolon.vue')
        },
        DRISSNET: {
            path: '/drissnet',
            name: 'drissnet',
            meta: {
                title: 'page.menu-title.detail.drissnet',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Drissnet.vue')
        },
        BLOATRINT: {
            path: '/bloatrint',
            name: 'bloatrint',
            meta: {
                title: 'page.menu-title.detail.bloatrint',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Bloatrint.vue')
        },
        TURKOITT: {
            path: '/turkoitt',
            name: 'turkoitt',
            meta: {
                title: 'page.menu-title.detail.turkoitt',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Turkoitt.vue')
        },
        EKENTRI: {
            path: '/ekentri',
            name: 'ekentri',
            meta: {
                title: 'page.menu-title.detail.ekentri',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Ekentri.vue')
        },
        STIIQUA: {
            path: '/stiiqua',
            name: 'stiiqua',
            meta: {
                title: 'page.menu-title.detail.stiiqua',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Stiiqua.vue')
        },
        PUISONTRY: {
            path: '/puisontry',
            name: 'puisontry',
            meta: {
                title: 'page.menu-title.detail.puisontry',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Puisontry.vue')
        },
        FOSHETT: {
            path: '/foshett',
            name: 'foshett',
            meta: {
                title: 'page.menu-title.detail.foshett',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Foshett.vue')
        },
        WVENTRY: {
            path: '/wventry',
            name: 'wventry',
            meta: {
                title: 'page.menu-title.detail.wventry',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Wventry.vue')
        },
        OXAVETT: {
            path: '/oxavett',
            name: 'oxavett',
            meta: {
                title: 'page.menu-title.detail.oxavett',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Oxavett.vue')
        },
        PAIVANT: {
            path: '/paivant',
            name: 'paivant',
            meta: {
                title: 'page.menu-title.detail.paivant',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Paivant.vue')
        },
        SKVAIL: {
            path: '/skvail',
            name: 'skvail',
            meta: {
                title: 'page.menu-title.detail.skvail',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Skvail.vue')
        },
        JEVUAE: {
            path: '/jevuae',
            name: 'jevuae',
            meta: {
                title: 'page.menu-title.detail.jevuae',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Jevuae.vue')
        },
        PEUGUAY: {
            path: '/peuguay',
            name: 'peuguay',
            meta: {
                title: 'page.menu-title.detail.peuguay',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Peuguay.vue')
        },
        DAVEECE: {
            path: '/daveece',
            name: 'daveece',
            meta: {
                title: 'page.menu-title.detail.daveece',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Daveece.vue')
        },
        GENACRYST: {
            path: '/genacryst',
            name: 'genacryst',
            meta: {
                title: 'page.menu-title.detail.genacryst',
                permissions: []
            },
            component: () => import('@/views/pages/characters/Genacryst.vue')
        },
        AERIALIMPALER: {
            path: '/Aerial_Impaler',
            name: 'Aerial_Impaler',
            meta: {
                title: 'page.menu-title.detail.Aerial_Impaler',
                permissions: []
            },
            component: () => import('@/views/pages/characters/AerialImpaler.vue')
        },
        LANCINGPUSHER: {
            path: '/Lancing_Pusher',
            name: 'Lancing_Pusher',
            meta: {
                title: 'page.menu-title.detail.Lancing_Pusher',
                permissions: []
            },
            component: () => import('@/views/pages/characters/LancingPusher.vue')
        },
        BUBBYBLASTER: {
            path: '/Bubby_Blaster',
            name: 'Bubby_Blaster',
            meta: {
                title: 'page.menu-title.detail.Bubby_Blaster',
                permissions: []
            },
            component: () => import('@/views/pages/characters/BubbyBlaster.vue')
        },
        PLASMATICANNIHILATOR: {
            path: '/Plasmatic_Annihilator',
            name: 'Plasmatic_Annihilator',
            meta: {
                title: 'page.menu-title.detail.Plasmatic_Annihilator',
                permissions: []
            },
            component: () => import('@/views/pages/characters/PlasmaticAnnihilator.vue')
        },
        PLUBBLESDISCHARGER: {
            path: '/Plubbles_Discharger',
            name: 'Plubbles_Discharger',
            meta: {
                title: 'page.menu-title.detail.Plubbles_Discharger',
                permissions: []
            },
            component: () => import('@/views/pages/characters/PlubblesDischarger.vue')
        },

        //================//
        //
        //
        //
        //================//

        PAGE: {}
    }
};

export default PageSpec;
